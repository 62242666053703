html {
    background-color: #ffffff;
    box-sizing: border-box;
}

body {
    font-family: 'Roboto', sans-serif;
}

:root {
    /*Font Size*/
    --h1-font-size: 63px;
    --h2-font-size: 42px;
    --p-font-size: 17px;
    --h3-font-size: 20px;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}

/* Heading Section */
.grid {
    display: grid;
    gap: 20px;
}
.col2-1 {
    grid-template-columns: 2fr 1fr;
}
.col1-2 {
    grid-template-columns: 1fr 2fr;
}
@media only screen and (max-width: 1025px) {
    .container {
        padding: 0 20px;
    }
    .grid {
        display: grid;
        grid-gap: 20px;
        gap: 20px;
    }
    .col2-1,
    .col1-2 {
        grid-template-columns: 1fr;
    }
}

/* Banner */
.banner {
    background: #ffffff;
    box-shadow: 0px 4px 105px rgba(34, 48, 74, 0.2);
    border-radius: 0px 0px 10px 10px;
    padding: 10px 20px 10px 10px;
    display: grid;
    gap: 30px;
    align-items: center;
    grid-template-columns: 0.8fr 2fr 0.7fr;
}
.banner .logo-box {
    background: #eaf2f8;
    border-radius: 10px;
}
.banner .logo-box a {
    background: url(../image/domainer_logo.svg);
    background-repeat: no-repeat;
    background-position: center center;
    color: transparent;
    display: block;
    font-size: 0;
    padding: 20px 30px;
    height: 138px;
}
.banner .title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    color: #090909;
    margin-bottom: 15px;
}
.banner .info {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #7d7f82;
    position: relative;
    margin-left: 15px;
    background-color: transparent;
    padding: 0;
}
.banner .info:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 6px;
    height: 100%;
    margin-left: -15px;
    border-radius: 10px;
    background: #f27558;
}
.banner .domaner-btn {
    background: #f27558;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
    padding: 23px 5px;
    display: block;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #000000;
}
.banner .domaner-btn:hover {
    background-color: rgb(249, 173, 141);
}
@media only screen and (max-width: 1090px) {
    .banner .title {
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 10px;
    }
    .banner .info {
        font-size: 18px;
    }
    .banner .domaner-btn {
        font-size: 18px;
    }
    .banner .domaner-btn {
        font-size: 18px;
        line-height: 22px;
        display: table;
        margin: 0 auto;
        padding: 23px 30px;
    }
}
@media only screen and (max-width: 940px) {
    .banner {
        grid-template-columns: 1fr;
        padding: 10px;
    }
    .banner .logo-box a {
        background: url(../image/domainer_logo_mob.svg);
        background-repeat: no-repeat;
        background-position: center center;
        height: 66px;
    }
    .banner .title {
        line-height: 24px;
        text-align: center;
    }
    .banner .info {
        font-size: 16px;
        line-height: 20px;
        text-align: center;
    }
    .banner .info:before {
        content: none;
    }
}
@media only screen and (max-width: 440px) {
    .banner .domaner-btn {
        display: block;
        padding: 23px 0px;
    }
}

.site-header .site-logo {
    padding: 20px 0;
}

.site-header .site-logo .main-logo,
.footer .main-logo {
    display: inline-block;
    font-size: 0;
    height: 48px;
    position: relative;
    vertical-align: middle;
    width: 250px;
}
.site-header .site-logo .main-logo {
    background: url(../image/logo.png) no-repeat 0 0 / contain;
}
.site-header {
    color: #fff;
}
.site-header-inner {
    background-color: #f9aa33;
    position: relative;
    padding: 108px 406px 0px 206px;
}
.header-section {
    padding: 50px 0;
}
.avatar {
    background-color: #fff0d2;
    border-radius: 50%;
    width: 350px;
    height: 350px;
    position: relative;
}
.avatar::after {
    content: '';
    background-image: url(../image/avatar.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
}
.site-header .container {
    position: relative;
}
.site-header::before {
    content: '';
    height: 700px;
    width: 700px;
    background-color: #f96333;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    top: -250px;
    right: 0px;
    display: block;
}

.btn-group {
    scroll-behavior: smooth;
}
.intro {
    font-weight: 300;
    font-size: 17px;
    line-height: 30px;
    color: #272525;
}

.internal {
    margin-bottom: 10px;
}
.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}
.container-heading {
    width: 100%;
    background: rgb(249, 218, 67);
    background: radial-gradient(circle, rgba(249, 218, 67, 1) 0%, rgba(249, 170, 51, 1) 100%);
    background-repeat: no-repeat;
}
@media (min-width: 1450px) {
    .site-header::before {
        right: 200px;
    }
}
@media only screen and (max-width: 1067px) {
    .avatar {
        width: 250px;
        height: 250px;
        grid-row: 1;
        margin: 0 auto;
    }
    h1 {
        margin-bottom: 30px !important;
    }
}

.white-h1 {
    color: white;
}
h1 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 63px;
    line-height: 70px;
    text-transform: uppercase;
    color: #2f2e2e;
    margin-bottom: 42px;
}

.white-h1 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 63px;
    line-height: 70px;
    text-transform: uppercase;
    color: #f3f3f3;
}

.header-text {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 30px;
    color: #272525;
    mix-blend-mode: normal;
    width: 65%;
}

.header-description {
    margin-bottom: 60px;
}

/* Navigation */

.btn-group {
    box-shadow: 0px 6px 17px rgba(0, 0, 0, 0.14);
    border-radius: 22px;
    width: 100%;
    display: grid;
    grid-template-columns: 3fr 3fr 3fr 2fr 1fr;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    top: 40px;
}
.btn {
    display: inline-block;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    cursor: pointer;
    color: #ffffff;
    background-color: #f96333;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: none;
    padding: 23px 0px;
    border-radius: 22px;
    transition: background-color 0.15s ease-in-out;
    width: 100%;
}
.btn:hover {
    color: #fff;
    background-color: #e64949;
}
.btn-group li:not(:last-child):not(.dropdown-toggle) .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 1px solid #ff8b66;
}
.btn-group li:not(:first-child) .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.btn-group .btn + .btn {
    margin-left: -5px;
}
.btn-group > .btn:first-child {
    margin-left: 0;
}
@media only screen and (max-width: 1067px) {
    .site-header .site-nav {
        z-index: auto;
    }
    .nav-visible .site-logo {
        padding-top: 10px;
    }
    .site-nav .icon {
        border-top: 2px solid white;
        height: 25px;
        width: 30px;
        box-sizing: border-box;
        position: absolute;
        z-index: 30;
        right: 20px;
        top: 25px;
        cursor: pointer;
        -webkit-transition: all 0.3s ease-in;
        -moz-transition: all 0.3s ease-in;
        -khtml-transition: all 0.3s ease-in;
        -o-transition: all 0.3s ease-in;
        transition: all 0.3s ease-in;
    }
    .site-nav .icon:before {
        content: '';
        display: block;
        position: absolute;
        height: 2px;
        width: 30px;
        right: 0;
        background: white;
        top: 10px;
        -webkit-transition: all 0.3s ease-in;
        -moz-transition: all 0.3s ease-in;
        -khtml-transition: all 0.3s ease-in;
        -o-transition: all 0.3s ease-in;
        transition: all 0.3s ease-in;
    }
    .site-nav .icon:after {
        content: '';
        display: block;
        position: absolute;
        height: 2px;
        width: 30px;
        right: 0;
        background: white;
        bottom: 0;
        -webkit-transition: all 0.3s ease-in;
        -moz-transition: all 0.3s ease-in;
        -khtml-transition: all 0.3s ease-in;
        -o-transition: all 0.3s ease-in;
        transition: all 0.3s ease-in;
    }
    .nav-visible .site-nav .icon {
        border: transparent;
    }
    .nav-visible .site-nav .icon:before {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -khtml-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        width: 33px;
        right: -2px;
    }
    .nav-visible .site-nav .icon:after {
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -khtml-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: rotate(135deg);
        width: 33px;
        right: -2px;
        top: 10px;
    }
    .nav-visible .site-nav {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        overflow-x: hidden;
        overflow-y: auto;
        text-align: left;
        z-index: 5;
        background: #f96333;
        background: linear-gradient(90deg, #f96333 0%, #f9aa33 100%);
    }
    .site-header.nav-visible .container {
        display: flow-root;
    }
    .site-nav > ul {
        opacity: 0;
        visibility: hidden;
        display: none;
    }
    .nav-visible .site-nav > ul {
        display: block;
        justify-content: center;
        align-items: center;
        opacity: 1;
        visibility: visible;
        position: fixed;
        width: 100%;
        height: 70%;
        min-width: 320px;
        top: 70px;
        left: 0;
        bottom: 0;
        overflow-x: hidden;
        overflow-y: auto;
        text-align: center;
        padding: 15px;
    }
    .nav-visible .site-nav > ul > li:hover {
        background-color: #ffffff40;
        border-radius: 22px;
    }

    .nav-visible .site-nav > ul > li a {
        font-weight: 600;
        font-size: 20px;
        line-height: 25px;
        text-align: center;
        color: #f3f3f3;
        white-space: break-spaces;
    }

    .nav-visible .btn-group {
        box-shadow: none;
    }
    .nav-visible .site-nav > ul > li a.btn,
    .nav-visible .btn-group li:not(:last-child):not(.dropdown-toggle) .btn,
    .nav-visible .btn-group li:not(:first-child) .btn {
        border: none;
        background-color: transparent;
    }
}

@media (min-width: 1300px) {
    a.button {
        min-width: 170px;
    }
}
.bg-grey {
    background-color: #f7f7f7;
}
.block {
    padding: 50px 0;
}
@media only screen and (max-width: 867px) {
    .block {
        padding: 30px 0;
    }
    #projects .margin-top-30 {
        margin-top: 70px;
    }
    h1,
    .white-h1 {
        font-size: 30px !important;
        line-height: 40px !important;
    }
}
/* Types */

.type {
    border-radius: 22px;
    padding: 0px 40px 20px 40px;
    position: relative;
    color: white;
}
.type::before {
    content: '';
    width: 250px;
    height: 200px;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
}
.type.bg-light::before {
    float: left;
    margin-bottom: 40px;
    background-image: url('../image/collection.png');
}
.type.bg-dark::before {
    float: left;
    background-image: url('../image/pfp-avatar.png');
}
.type.bg-dark-two::before {
    float: right;
    margin: 0 0 0 50px;
    background-image: url('../image/manage-your-collection.png');
}

.type.bg-light {
    top: 30px;
}
.type.bg-dark {
    top: 80px;
    padding-bottom: 50px;
}
.type.bg-dark-two {
    top: 55px;
}
.bg-light {
    background: #f9aa33;
}
.bg-dark {
    background: #f96333;
}
.type.bg-dark-two {
    background: #e64949;
    box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.15);
    display: inline-block;
}

.type h3 {
    padding-top: 40px;
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    text-transform: uppercase;
}
.type a {
    color: white;
    text-decoration: underline;
    font-weight: 800;
}
@media only screen and (max-width: 867px) {
    .type {
        padding: 0 20px;
    }
    .type.bg-light::before,
    .type.bg-dark::before,
    .type.bg-dark-two::before,
    .type.bg-dark-three::before {
        display: block;
        margin: 0 auto 5px;
        float: unset;
    }
    .type h3 {
        padding-top: 0px;
    }
    .type.bg-dark-three {
        top: 165px;
    }
    #type-of-marketplaces.block {
        margin-top: 100px;
    }
}

/* F&Q */
.faq-wrapper {
    padding: 60px 0 80px;
}
.faq-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 20px 30px;
}
.faq-title h3 {
    font-size: 20px;
    line-height: 23px;
    color: #454545;
    text-transform: none;
}
.faq.expanded .faq-title {
    padding-bottom: 20px;
    border-bottom: 1px solid #e3e3e359;
}

.faq-btn {
    position: relative;
    float: right;
    height: 20px;
    width: 30px;
}
.faq-btn::after {
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    margin: 0 auto;
    width: 10px;
    height: 10px;
    border-top: 2px solid grey;
    border-left: 2px solid grey;
    transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    -webkit-transform: rotate(225deg);
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
    -ms-transition: -ms-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
}
.faq.expanded .faq-btn::after {
    transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.faq .faq-btn:hover {
    cursor: pointer;
}

.faq {
    transition: all 0.1s ease-in;
    background-color: white;
    border-radius: 22px;
}
.faq + .faq {
    margin-top: 30px;
}
.faq.collapsed .faq-body {
    display: none;
}
.faq-body {
    padding: 0 30px;
}
.faq.expanded {
    background: white;
    border-radius: 22px;
    border: none;
}

.faq.faq.expanded + .faq {
    border-top: 0px;
}

.faq.expanded .faq-title h3 {
    margin-bottom: 0px;
}

.faq .faq-title h3 {
    pointer-events: none;
}

.faq-listing {
    margin-top: 30px;
}

.read-more-faq {
    width: 100%;
    border: 1px solid #e64949;
    border-radius: 69px;
    height: 59px;
    background: transparent;
    font-weight: bold;
    font-size: 16px;
    font-family: Poppins;
    font-style: normal;
    line-height: 24px;
    color: #e64949;
}

.read-more-faq:active {
    background-color: #e2fcfc;
}

@media only screen and (max-width: 867px) {
    .faq-wrapper {
        margin-top: 30px;
    }
    .faq,
    .faq-title {
        padding: 10px;
    }
    .faq.expanded {
        border-radius: 15px;
    }
}

.software-img {
    background-image: url(../image/software.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
}
@media only screen and (max-width: 867px) {
    .software-img {
        height: 250px;
    }
}
/*Contact Us*/
.contact-us-outer {
    position: relative;
    background: #2b2e33;
}

.contact-us-outer .button:hover {
    color: white;
    background: rgb(255, 134, 95);
    background: radial-gradient(circle, rgba(255, 134, 95, 1) 0%, rgba(249, 99, 51, 1) 100%);
}
.contact-btn-box {
    text-align: center;
    width: fit-content;
    margin: 0 auto;
}
.contact-btn-box .button {
    cursor: pointer;
}
/* .contact-btn-box .button:hover:before {
  background: #000;
    border-radius: 69px;
    bottom: 0;
    box-shadow: 0 4px 15px rgb(26 70 79 / 30%);
    color: #aaa;
    content: "Services under construction";
    display: inline;
    font-size: 14px;
    height: inherit;
    left: 0;
    line-height: 24px;
    opacity: .95;
    padding: 15px 5px;
    position: absolute;
    text-align: center;
    transform: translateY(-80px);
    width: inherit;
}
.contact-btn-box .button:hover:after {
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-top: 8px solid black;
  clear: both;
  content: "";
  height: 0;
  left: 47%;
  opacity: .95;
  position: absolute;
  transform: translateY(-16px);
  width: 0;
  top: -10px;
} */
.button {
    -webkit-backface-visibility: hidden;
    background: rgb(250, 100, 100);
    background: radial-gradient(circle, rgba(250, 100, 100, 1) 0%, rgba(230, 73, 73, 1) 100%);
    border-radius: 69px;
    display: inline-block;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    padding: 15px 30px;
    white-space: nowrap;
    z-index: 1;
    color: #fff;
    position: relative;
    text-decoration: none;
}
.button:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 69px;
    background: rgb(250, 100, 100);
    background: radial-gradient(circle, rgba(250, 100, 100, 1) 0%, rgba(230, 73, 73, 1) 100%);
    z-index: 2;
    opacity: 0;
}
/* .button:hover:after {
  opacity: 1;
} */
.button a {
    position: relative;
    z-index: 3;
}
@media (min-width: 1300px) {
    a.button {
        min-width: 170px;
    }
}
@media only screen and (max-width: 1067px) {
    a.button {
        box-shadow: none;
        display: block;
        min-width: auto;
        padding: 20px;
        text-align: center;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}
@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}
@media (min-width: 1400px) {
    .container {
        max-width: 1200px;
    }
}

h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 60px;
    line-height: 72px;
    margin-bottom: 72px;
}

h1 .thin {
    font-weight: 300;
}

h2 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 33px;
    line-height: 39px;
    text-align: center;
    text-transform: uppercase;
    color: #454545;
    margin-bottom: 20px;
}

p {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 30px;
    color: #505050;
    padding: 20px 0;
}
a {
    color: #198aca;
    font-weight: 700;
    text-decoration: none;
}
.text-center {
    text-align: center;
}
.text-white {
    color: #ffffff;
}
.bold {
    font-weight: 800;
}
ul {
    list-style: none;
}
.margin-top-30 {
    margin-top: 30px;
}
footer {
    background-color: #202022;
}

footer .container {
    text-align: center;
    padding: 20px;
}

footer .copyright {
    text-align: center;
    color: #707278;
    font-size: 15px;
    line-height: 18px;
}

.m-visible {
    display: none;
}

.m-hidden {
    display: initial;
}

@media only screen and (max-width: 1024px) {
    .manage-collection::after {
        content: '';
        background-image: url(http://localhost:7022/image/manage-your-collection.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        position: absolute;
        display: block;
        width: 300px;
        height: 300px;
        margin-left: 450px;
    }

    .our-features {
        background-color: #e5e5e5;
        width: 100%;
        height: 1070px;
    }
}

@media only screen and (max-width: 768px) {
    header,
    article,
    footer {
        padding: 0px 22px;
    }

    h1 {
        font-size: 32px;
        line-height: 38px;
        margin-bottom: 24px;
    }

    h2 {
        font-size: 28px;
        line-height: 36px;
    }

    .mobile-detector {
        display: none;
    }

    .m-visible {
        display: initial;
    }

    .m-hidden {
        display: none;
    }
    header,
    article,
    footer {
        padding: 0;
    }
}
/* Read more */

.m-slide-down {
    height: 0;
    overflow: hidden;
    -moz-transition: height 0.5s;
    -ms-transition: height 0.5s;
    -o-transition: height 0.5s;
    -webkit-transition: height 0.5s;
    transition: height 0.5s;
}
.m-hidden,
.mobile-detector {
    display: none;
}
.m-visible {
    display: initial;
}
.m-hidden {
    display: initial;
}
.m-slide-down-measure p {
    margin: 0 !important;
    padding-top: 20px;
}
.m-readmore-btn {
    background: transparent;
    border: none;
    text-transform: uppercase;
    font-size: 0;
    font-weight: 500;
    color: white;
    width: 100%;
    margin: 15px auto 50px;
}

.m-readmore-btn::before {
    cursor: pointer;
    display: inline-block;
    width: 25px;
    height: 25px;
    vertical-align: top;
    margin-left: 5px;
    color: rgb(22, 22, 22);
    font-size: 24px !important;
}

.m-readmore-btn.read-more-2::before {
    content: '\002B';
}
.m-readmore-btn.collapse-2::before {
    content: '\2212';
}

.m-readmore-btn * {
    pointer-events: none;
}
.m-visible {
    display: initial;
}
.m-hidden,
.mobile-detector {
    display: none;
}
