.slider {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #e3e6eb;
  height: 125px;
  padding: 18px;
  border-radius: 22px;
}

@media only screen and (max-width: 768px) {
  .slider {
    padding: 10px;
  }
}

.slider .slider-btn {
  width: 36px;
  height: 36px;
  border-radius: 36px;
  border: none;
  background-color: #fff;
  color: #6b6b6b;
  font-size: 18px;
  text-align: center;
  outline: none;
  opacity: 0.5;
  transition: opacity 300ms ease-out;
}

@media only screen and (max-width: 768px) {
  .slider .slider-btn {
    width: 32px;
    height: 32px;
    font-size: 16px;
  }
}

.slider .slider-btn:hover {
  opacity: 1;
  cursor: pointer;
}

.slider .slider-btn.prev {
  margin-right: 19px;
}

.slider .slider-btn.next {
  margin-left: 19px;
}

@media only screen and (max-width: 768px) {
  .slider .slider-btn.prev {
    margin-right: 10px;
  }

  .slider .slider-btn.next {
    margin-left: 10px;
  }
}

.slider .scroller {
  flex: 1;
  overflow: hidden;
}

.slider .items {
  list-style-type: none;
  white-space: nowrap;
  font-size: 0;
  line-height: 0;
  transition: transform 0.7s ease-in-out;
}

.slider .item-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  .slider .item-wrapper {
    flex-direction: column;
  }
}

.slider .item {
  padding: 24px;
  display: inline-block;
  width: 330px;
  line-height: 1;
  font-weight: bold;
  background-color: #fff;
  border-radius: 22px;
}

@media only screen and (max-width: 768px) {
  .slider .item {
    padding: 16px;
    width: 150px;
  }
}

.slider .items .item:not(:last-child) {
  margin-right: 18px;
}

@media only screen and (max-width: 768px) {
  .slider .items .item:not(:last-child) {
    margin-right: 10px;
  }
}

.slider .item img {
  width: 46px;
  height: 46px;
  border-radius: 23px;
  margin-right: 15px;
}

@media only screen and (max-width: 768px) {
  .slider .item img {
    margin-right: 0px;
    margin-bottom: 10px;
  }
}

.slider .item-details {
  text-align: left;
}

@media only screen and (max-width: 768px) {
  .slider .item-details {
    text-align: center;
  }
}

.slider .item-name a {
  font-family: Roboto;
  font-weight: 900;
  font-size: 18px;
  text-transform: uppercase;
  text-decoration: none;
  color: #e64949;
  margin-bottom: 12px;
}

@media only screen and (max-width: 768px) {
  .slider .item-name a {
    font-size: 11px;
    margin-bottom: 8px;
  }
}

.slider .item-stats {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #b0adb1;
}

@media only screen and (max-width: 768px) {
  .slider .item-stats {
    font-size: 10px;
  }
}
